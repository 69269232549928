import { handleFormSubmit, handleClickOutsideForm } from '@/utils/forms';

document.addEventListener("DOMContentLoaded", function () {
    const formModalContainer: HTMLElement | null = document.querySelector("#contact-form");
    const closeButton: HTMLElement | null = document.querySelector(".closeContactForm");
    const triggers: NodeListOf<HTMLElement> = document.querySelectorAll('.openContactForm');
    const submitButton: HTMLElement | null = document.querySelector(".submitContactForm");
    let hasListernerSetup = false;

    triggers?.forEach(item => {
        item?.addEventListener('click', (event) => {
            event.stopPropagation(); // Prevent the click from propagating to the window
            if (formModalContainer) {
                formModalContainer.classList.toggle('hidden');
            }
            
            if(formModalContainer && !formModalContainer.classList.contains('hidden') && !hasListernerSetup) {
                hasListernerSetup = true;
                handleClickOutsideForm(formModalContainer);
            }
        })
    });

    closeButton?.addEventListener('click', () => {
        if (formModalContainer) {
            formModalContainer.classList.add('hidden');
        }
    });

    formModalContainer?.addEventListener('submit', (event) => {
        event.preventDefault();
        
        const formData = new FormData(event.target as HTMLFormElement);
        handleFormSubmit(formData, submitButton, 'contact-form', 'contact');
    });
});