import { handleFormSubmit, getProgramContactSession } from '@/utils/forms';

let gtmIdOperation: string | null;
let gtmProgramName: string | null;
let nb_pieces: string | null = null;
let plan_url: string | null = null;

const getFormAttributes = (element: HTMLElement) => {
    const form: HTMLElement | null = element.querySelector('form');
    if(!form) return;

    gtmIdOperation = form.getAttribute('data-program-id-operation');
    gtmProgramName = form.getAttribute('data-program-name');
}

document.addEventListener("DOMContentLoaded", function () {
    const form: HTMLElement | null = document.querySelector(".plan-form");

    if(!form) return;

    getFormAttributes(form);
    
    const submitButton: HTMLElement | null = document.querySelector(".submitPlanForm");

    document.querySelectorAll('.openPlanForm')?.forEach(item => {
        item?.addEventListener('click', () => {
            if(item.getAttribute('data-nb_pieces')) {
                nb_pieces = item.getAttribute('data-nb_pieces');
                if(nb_pieces && submitButton) {
                    submitButton?.setAttribute('data-nb_pieces', nb_pieces)
                }
            }
            
            if(item.getAttribute('data-plan_url')) {
                plan_url = item.getAttribute('data-plan_url');
                if(plan_url && submitButton) {
                    submitButton?.setAttribute('data-plan_url', plan_url)
                }
            }

            if(!!gtmIdOperation && getProgramContactSession(gtmIdOperation, 'plan', parseInt(nb_pieces ?? '0'))) {
                const button = form.querySelector('.downloadPlanProgram');
                if(button) {   
                    button.setAttribute('href', plan_url ?? '');
                    button.classList.remove('hidden');
                }
                form.querySelector('form')?.classList.add('!hidden');
            } else {
                form.querySelector('.downloadPlanProgram')?.classList.add('hidden');
                form.querySelector('form')?.classList.remove('!hidden');
            }

            form?.classList.toggle('hidden');

        })
    });

    document.querySelector('#closePlanForm')?.addEventListener('click', () => {
        form?.classList.add('hidden');
    })

    form?.addEventListener('submit', (event) => {
        event.preventDefault();

        const formData = new FormData(event.target as HTMLFormElement);
        
        if(!!nb_pieces) formData.append('nb_piece', nb_pieces);

        handleFormSubmit(formData, submitButton, 'plan-form', 'plan', {
                    form: {
                        id: "Rappel form",
                        title: "Rappel form"
                    },
                    program: {
                        id: gtmIdOperation ?? '',
                        title: gtmProgramName ?? '',
                    }
                });
    })
});
