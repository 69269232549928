import axios from 'axios'
import { gtmEvent } from '../../scripts/includes/gtm';

interface GtmPayload {
    form: {
        id: string;
        title: string;
    };
    success: boolean;
    program?: {
        id: string;
        title: string;
    };
}

const resetInputStyle = (form: Element, selector: string) => {
    form?.querySelector(`[name="${selector}"]`)?.classList.remove('border-red');
    form?.querySelector(`[for="${selector}"]`)?.classList.remove('text-red');
}

export const getProgramContactSession = (programId: string, type: 'plan' | 'brochure', rooms?: number) => {
    switch(type) {
        case 'plan':
            {
                const plans = sessionStorage.getItem('lnc_available_programs_plans');
                if(!plans) return false;
                
                const sessionsObj = JSON.parse(plans);

                const sessionFound = sessionsObj.find((session: { id: string; rooms: number; url: string }) => {
                    return session.id === programId && session.rooms === rooms;
                })
                
                return !!sessionFound
            }
        default:
            {
                const booklets = sessionStorage.getItem('lnc_available_programs_booklets');
                if(!booklets) return false;
                
                const sessionsObj = JSON.parse(booklets);
                return sessionsObj.includes(programId);   
            }
    }
}

export const setProgramContactSession = (programId: string, type: 'plan' | 'brochure', rooms?: number, plan_url?: string) => {
    if(type === 'plan') {
        sessionStorage.setItem('lnc_available_programs_plans', JSON.stringify([...(JSON.parse(sessionStorage.getItem('lnc_available_programs_plans') || '[]')), {id: programId, rooms, plan_url}]));
    } else {
        sessionStorage.setItem('lnc_available_programs_booklets', JSON.stringify([...(JSON.parse(sessionStorage.getItem('lnc_available_programs_booklets') || '[]')), programId]));
    }
}

export const handleFormValidation = (form: Element | null, formData: FormData) => {
    const errors = [];

    if(!form) return;
    // console.log('formData :', ...formData.entries());

    if(formData.get('telephone')) {
        resetInputStyle(form, 'telephone');
        const phone = formData.get('telephone') as string;
        const phoneRegex = new RegExp('^(?:(?:\\+33|0033|0)(?:\\s|-|\\.|)?(?:[1-9])(?:\\s|-|\\.|)?(?:\\d{2}(?:\\s|-|\\.|)?){4})$');
        if(!phoneRegex.test(phone)) {
            errors.push({
                field: 'telephone',
                message: 'Veuillez renseigner un numéro de téléphone valide'
            });
        }
    }

    if(formData.get('phone')) {
        resetInputStyle(form, 'phone');
        const phone = formData.get('phone') as string;
        const phoneRegex = new RegExp('^(?:(?:\\+33|0033|0)(?:\\s|-|\\.|)?(?:[1-9])(?:\\s|-|\\.|)?(?:\\d{2}(?:\\s|-|\\.|)?){4})$');
                
        if(!phoneRegex.test(phone)) {
            errors.push({
                field: 'phone',
                message: 'Veuillez renseigner un numéro de téléphone valide'
            });
        }
    }

    if(formData.get('email')) {
        resetInputStyle(form, 'email');
        const email = formData.get('email') as string;
        const emailRegex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
        if(!emailRegex.test(email)) {
            errors.push({
                field: 'email',
                message: 'Veuillez renseigner une adresse email valide'
            });
        }
    }

    if(errors.length) {
        displayFormSubmitMessage('error', 'Il y a des erreurs dans un ou plusieurs champs');
    }

    return {
        errors
    };
};

export const displayFormSubmitMessage = (type: 'success' | 'error', message: string) => {
    const messageContainer: HTMLElement | null = document.querySelector('.submit_form_message');

    if(!messageContainer) return;

    messageContainer.classList.remove('hidden');
    messageContainer.classList.add(type === 'success' ? 'bg-green' : 'bg-red');
    messageContainer.innerText = message;

    setTimeout(() => {
        messageContainer.classList.add('hidden');
    }, 5000);
}

export const toggleDisableSubmitBtn = (submitButton: HTMLElement | null, isDisabled: boolean) => {
    if (isDisabled) {
        submitButton?.setAttribute('disabled', 'true');
        submitButton?.classList.add('cursor-not-allowed', 'opacity-50');
        submitButton?.classList.remove('cursor-pointer', 'opacity-100');
    } else {
        submitButton?.removeAttribute('disabled');
        submitButton?.classList.remove('cursor-not-allowed', 'opacity-50');
        submitButton?.classList.add('cursor-pointer', 'opacity-100');
    }
};

const handleMailingInput = (formData: FormData) => {
    if(formData.get("mailing_ok") === 'on') {
        formData.set('mailing_ok', '1');
    } else {
        formData.set('mailing_ok', '0');
    }
}

export const handleInvestInput = (formSelector: string, formData: FormData) => {
    const form = document.querySelector(`.${formSelector}`);

    // @ts-ignore
    form?.querySelectorAll('input[name="habiter"]').forEach((input: HTMLInputElement) => {
        if(input.id === 'habiter') {
            if(input.checked) {
                input.value = '1';
                formData.set('habiter', '1');
            } else {
                input.value = '0';
                formData.set('habiter', '0');
            }
        } else if(input.id === 'is_investisseur') {
            if(input.checked) {
                input.value = '1';
                formData.append('is_investisseur', '1');
            } else {
                input.value = '0';
                formData.append('is_investisseur', '0');
            }
        } else {
            formData.set('habiter', '0');
            formData.append('is_investisseur', '0');
        }
    })
};

export const handleFormSubmit = async (
    formData: FormData,
    submitButton: HTMLElement | null,
    formDataName: string,
    formType: 'contact' | 'program' | 'rappel' | 'terrain' | 'brochure' | 'plan',
    gtmPayload?: Partial<GtmPayload>
) => {
    let submit = false
    let autoClose = true

    try {
        const form: HTMLFormElement | null = document.querySelector(`.${formDataName}`);

        const { errors } = handleFormValidation(form, formData) ?? { errors: [] };

        if(errors.length) {
            console.error('errors :', errors);
            errors.forEach((error) => {
                form?.querySelector(`[name="${error.field}"]`)?.classList.add('border-red');
                form?.querySelector(`[for="${error.field}"]`)?.classList.add('text-red');
            })

            return;
        }
        
        toggleDisableSubmitBtn(submitButton, true);

        // Set habiter and is_investisseur values
        handleInvestInput(formDataName, formData);

        // Set mailing_ok value for optin
        handleMailingInput(formData);

        // @ts-ignore
        const response = await axios.post(urls.ajax, formData);

        if(response.status !== 200) { 
            throw new Error(`Failed to submit ${formType} form`) 
        } else {
            submit = true
            displayFormSubmitMessage('success', `Votre message a bien été envoyé`);
    
            if(gtmPayload) {
                gtmEvent('Lead', {
                    ...gtmPayload,
                    success: true
                });
            }

            if((formType === 'brochure' || formType === 'plan') && formData.get('id_operation')) {
                if(formType === 'brochure') {
                    if (!getProgramContactSession(formData.get('id_operation') as string, 'brochure')) {
                        setProgramContactSession(formData.get('id_operation') as string, 'brochure');
                    }
    
                    form?.querySelector('form')?.classList.add('!hidden');
                    form?.querySelector('.downloadBookletProgram')?.classList.remove('hidden');
                    autoClose = false;
                } else {
                    const rooms = submitButton?.getAttribute('data-nb_pieces');
                    const plan_url = submitButton?.getAttribute('data-plan_url');

                    if(!getProgramContactSession(formData.get('id_operation') as string, 'plan') && plan_url) {
                        setProgramContactSession(formData.get('id_operation') as string, 'plan', parseInt(rooms || '0'), plan_url);
                    }
                    
                    if(plan_url) {
                        form?.querySelector('form')?.classList.add('!hidden');
    
                        form?.querySelector('.downloadPlanProgram')?.setAttribute('href', plan_url || '');
                        form?.querySelector('.downloadPlanProgram')?.classList.remove('hidden');
                        autoClose = false;
                    }

                }
            }

            form?.reset();
        }
    } catch (error) {
        console.error(error);
        displayFormSubmitMessage('error', `Une erreur est survenue lors de l'envoi du message pour le formulaire ${formType}`);
        if(gtmPayload) {
            gtmEvent('Lead', {
                ...gtmPayload,
                success: false
            });
        }
    } finally {
        if(submit) {
            toggleDisableSubmitBtn(submitButton, false);
            
            if(autoClose) {
                setTimeout(() => {
                    const form = document.querySelector(`.${formDataName}`);
                    if(form) form.classList.add('hidden');
                }, 5000);
            }
        }
    }
};

export function debounce<F extends (...args: Parameters<F>) => void>(func: F, timeout: number) {
	let timeoutId: ReturnType<typeof setTimeout>;

	return (...args: Parameters<F>): void => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => {
			func(...args);
		}, timeout);
	};
}

export function handleClickOutsideForm(formContainer: HTMLElement) {
    const handleClick = (event: MouseEvent) => {
        const form = formContainer.querySelector('.form-element');
        setTimeout(() => {
            if (form && !formContainer.classList.contains('hidden')) {
                if (!form.contains(event.target as Node)) {
                    formContainer.classList.add('hidden');        
                }
            } 
        }, 10);
    }

    window.addEventListener('click', (event) => {
        event.stopPropagation();        
        handleClick(event);
    });
}