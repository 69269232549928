import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useSearchFormStore = defineStore('searchForm', () => {
    // STATE
    const pricesList = [
        "Non précisé",
        "Moins de 100 000€",
        "Entre 100 000€ et 200 000€",
        "Entre 200 000€ et 300 000€",
        "Entre 300 000€ et 400 000€",
        "Entre 400 000€ et 500 000€",
        "Entre 500 000€ et 600 000€",
        "Entre 600 000€ et 700 000€",
        "Entre 700 000€ et 800 000€",
        "Entre 800 000€ et 900 000€",
        "Plus de 900 000€",
    ]

    const typesList = [
        "Appartement",
        "Maison",
        "Terrain",
        "Parking",
        "Local commercial",
        "Bureau",
        "Immeuble",
        "Fonds de commerce",
        "Duplex",
        "Triplex",
        "Autre",
    ]

    const roomsList = [
        "Studio",
        "2 pièces",
        "3 pièces",
        "4 pièces",
        "5 pièces et +",
    ]

    const dispositifsList = [
        {
            key: "PTZ",
            value: "ptz"
        },
        {
            key: "Offre spéciale",
            value: "offre spéciale"
        },
        {
            key: "TVA réduite",
            value: "tva réduite"
        },
        {
            key: "Prix maitrisés",
            value: "prix maitrises"
        },
        {
            key: "Nue-propriété",
            value: "nue propriete"
        },
        {
            key: "BRS",
            value: "brs"
        },
        {
            key: "LMNP",
            value: "lmnp"
        }
    ]

    const avancementList = [
        {
            key: 'Avant-première',
            value: 'avant-premiere'
        },
        {
            key: 'Lancement commercial',
            value: 'lancement'
        },
        {
            key: 'En commercialisation',
            value: 'commercialisation'
        },
        {
            key: 'Travaux en cours',
            value: 'travaux'
        },
        {
            key: 'Livraison immédiate',
            value: 'livraison'
        }
    ]

    const searchQuery = ref('')
    const isLocationSearch = ref(false);

    const userLocation = ref({
        latitude: 48.8566 as number,
        longitude: 2.3522 as number,
        distance: 0 as number,
    })

    const searchPagination = ref({
        currentPage: 1,
        maxPage: 1,
        totalCount: 0,
    });

    const formPayload = ref({
        types: [] as ProgramType[],
        rooms: [] as ProgramRoom[],
        price: '' as ProgramPrice,
        dispositifs: [] as ProgramDispositif[],
        status: [] as ProgramStatus[],
        places: [] as ProgramPlace[],

        perPage: 16 as number,
    });

    // METHODS
    const setFormPayload = (value: any) => {
        formPayload.value = value;
    }

    const resetPagination = () => {
        searchPagination.value = {
            currentPage: 1,
            maxPage: 1,
            totalCount: 0,
        }
    }

    const resetFormPayload = () => {
        formPayload.value = {
            types: [] as ProgramType[],
            rooms: [] as ProgramRoom[],
            price: '' as ProgramPrice,
            dispositifs: [] as ProgramDispositif[],
            status: [] as ProgramStatus[],
            places: [] as ProgramPlace[],

            perPage: 16 as number,
        };

        resetPagination();

        userLocation.value = {
            latitude: 48.8566 as number,
            longitude: 2.3522 as number,
            distance: 0 as number,
        }
    }

    // QUERY BUILDER

    const hasSearchParameters = () => {
        return Object.entries(formPayload.value).some(([key, value]) => {
            if (key === 'perPage') return false;
            if (Array.isArray(value)) return value.length > 0;
            return !!value;
        });
    }

    const buildSearchQuery = (includePagination: boolean = false) => {
        const params = new URLSearchParams();

        Object.entries(formPayload.value).forEach(([key, value]) => {
            if (!!value && key !== 'perPage') {
                if (Array.isArray(value)) {
                    if (value.length > 0) { // Only add array parameters if they're not empty
                        value.forEach((item, index) => {
                            if (key === 'places') {
                                // @ts-expect-error
                                params.set(`${key}[${index}]`, String(item?.value));
                            } else {
                                params.set(`${key}[${index}]`, String(item));
                            }
                        });
                    }
                } else {
                    params.set(key, String(value));
                }
            }
        });

        if (includePagination) {
            params.set('perPage', String(formPayload.value.perPage));
            params.set('page', String(searchPagination.value.currentPage));
        }

        return params.toString();
    }

    const updateSearchQuery = (useDistance: boolean = false, updateUrl: boolean = true) => {
        // Build URL-visible parameters (without pagination)
        const urlParams = buildSearchQuery(false);
        
        // Build complete query for API (with pagination)
        const apiParams = buildSearchQuery(true);
    
        // Never update URL if this is a location search
        if (isLocationSearch.value) {
            updateUrl = false;
        }
    
        // Update URL only if updateUrl is true and not a location search
        if (updateUrl && !isLocationSearch.value) {
            const url = new URL(window.location.href);
            if (hasSearchParameters()) {
                if (useDistance) {
                    const distanceParams = new URLSearchParams(urlParams);
                    distanceParams.set('latitude', String(userLocation.value.latitude));
                    distanceParams.set('longitude', String(userLocation.value.longitude));
                    distanceParams.set('distance', String(userLocation.value.distance));
                    url.search = distanceParams.toString();
                } else {
                    url.search = urlParams;
                }
            } else {
                url.search = '';
            }
            window.history.pushState({}, '', url);
        }
    
        // Always update the search query for API calls
        if (useDistance) {
            const apiDistanceParams = new URLSearchParams(apiParams);
            apiDistanceParams.set('latitude', String(userLocation.value.latitude));
            apiDistanceParams.set('longitude', String(userLocation.value.longitude));
            apiDistanceParams.set('distance', String(userLocation.value.distance));
            searchQuery.value = apiDistanceParams.toString();
        } else {
            searchQuery.value = apiParams;
        }
    }

    const searchByLocation = (type: 'department' | 'city', value: string) => {
        isLocationSearch.value = true;
        const newFormPayload = { ...formPayload.value };
        newFormPayload.places = [{ key: type, value }];
        formPayload.value = newFormPayload;
        
        // Trigger search without updating URL
        updateSearchQuery(false, false);
    }

    const isArrayKey = (key: string): key is 'types' | 'rooms' | 'dispositifs' | 'status' | 'places' => {
        return ['types', 'rooms', 'dispositifs', 'status', 'places'].includes(key);
    }
    
    const syncFromUrl = () => {
        const params = new URLSearchParams(window.location.search);
        const newFormPayload = { ...formPayload.value };
    
        // Reset all arrays
        newFormPayload.types = [];
        newFormPayload.rooms = [];
        newFormPayload.dispositifs = [];
        newFormPayload.status = [];
        newFormPayload.places = [];
    
        params.forEach((value, key) => {
            const match = key.match(/^(\w+)\[(\d+)\]$/);
            if (match) {
                const [, arrayKey, index] = match;
                if (isArrayKey(arrayKey)) {
                    if (arrayKey === 'places') {
                        newFormPayload[arrayKey][Number(index)] = { key: 'place', value };
                    } else {
                        // @ts-expect-error
                        newFormPayload[arrayKey][Number(index)] = value;
                    }
                }
            } else if (key === 'latitude') {
                userLocation.value.latitude = Number(value);
            } else if (key === 'longitude') {
                userLocation.value.longitude = Number(value);
            } else if (key === 'distance') {
                userLocation.value.distance = Number(value);
            } else if (key === 'price' && typeof value === 'string') {
                newFormPayload.price = value as ProgramPrice;
            } else if (key === 'perPage') {
                newFormPayload.perPage = Number(value);
            }
        });
    
        formPayload.value = newFormPayload;
    }

    return {
        pricesList,
        typesList,
        roomsList,
        dispositifsList,
        avancementList,
        formPayload,
        userLocation,
        searchPagination,
        searchQuery,
        isLocationSearch,
        syncFromUrl,
        updateSearchQuery,
        searchByLocation,
        setFormPayload,
        resetPagination,
        resetFormPayload
    };
}, { persist: {
        storage: sessionStorage,
        pick: ['formPayload'],
    }
})
