document.addEventListener("DOMContentLoaded", function () {
    setMap()
})

function setMap() {
    let domElement: HTMLElement | null = document.querySelector('#mainMap');

    if (!domElement) {
        return;
    }

    let lat: string | undefined = domElement.dataset.lat;
    let lng: string | undefined = domElement.dataset.lng;

    if(lat === undefined && lng === undefined) {
        return;
    }
    
    // @ts-ignore
    let kimonoMap = kimono.addMap({
        zoom: 18,
        theta: 55,
        phi: 5,
        lon: lng ?? 2.333333,
        lat: lat ?? 48.866667,
        container: domElement,
    });

    // @ts-ignore
    kimonoMap.on("load", function (realEstateAd, map) {
        if (
            !!lat && !!lng
        ) { 
            // @ts-ignore
            new f4.map.Marker({
                // @ts-ignore
                position: new f4.map.LatLng(lat, lng),
                map: map,
                icon: {
                    // @ts-ignore
                    url: urls.theme + "/assets/images/placeholder.png",
                },
            });
            
            // @ts-ignore
            map.setCenter(new f4.map.LatLng(lat, lng));
        }
    });

    return kimonoMap;
}