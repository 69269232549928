import "../vendor/pagination";

$(document).ready(function() {
    var filter_genre = "Type de métier";
    var filter_region = "Région";
    var filter_type = "Type de contrat";

    $(".collapse").each(function () {
        var collapse = $(this);
        var body = collapse
            .children(".collapse_wrapper")
            .children(".collapse_body");
        var section = body.children(".collapse_section");
        var footer = collapse
            .children(".collapse_wrapper")
            .children(".collapse_footer");
        var paginate = footer.children(".paginate");
        if (section.length > 6) {
            paginate.jPages({
                containerID: body.attr("id"),
                perPage: 6,
                first: false,
                previous: false,
                next: false,
                last: false,
            });
        }
    });

    $("body").on("click", ".collapse_button", function () {
        $(this).toggleClass("collapse_button-reverse");
        $(this).parent().parent().next().toggleClass("hidden");
    });

    $("body").on("click", ".collapse_title_button", function () {
        $(this).next().toggleClass("collapse_button-reverse");
        $(this).parent().parent().next().toggleClass("hidden");
    });

    $("body").on("click", ".finance_filter", function () {
        var value = $(this).children().text();
        var body = $(this).parent().parent().parent().parent().next();
        var id = body.attr("id");
        $(this)
            .parent()
            .children(".collapse_dropdown_filter")
            .each(function () {
                $(this).removeClass("collapse_dropdown_filter-active");
            });
        $(this).addClass("collapse_dropdown_filter-active");
        $(this)
            .parent()
            .parent()
            .children(".collapse_dropdown_header")
            .children(".collapse_dropdown_label")
            .text(value);
        $.get(
            urls.ajax,
            {
                action: "finance_filter",
                category: id,
                value: value,
            },
            function (data) {
                $(body).html(data);
                body.next().children(".paginate").jPages({
                    containerID: id,
                    perPage: 6,
                    first: false,
                    previous: false,
                    next: false,
                    last: false,
                });
            },
        );
    });

    $("body").on("change", ".finance_search", function () {
        var value = $(this).val();
        var body = $(this).parent().parent().next();
        var id = body.attr("id");
        $.get(
            urls.ajax,
            {
                action: "finance_search",
                category: id,
                value: value,
            },
            function (data) {
                $(body).html(data);
                body.next().children(".paginate").jPages({
                    containerID: id,
                    perPage: 6,
                    first: false,
                    previous: false,
                    next: false,
                    last: false,
                });
            },
        );
    });

    $("body").on("click", ".job_button", function () {
        if ($(this).data("action") === "show_modal") {
            if ($(this).data("type") !== "") {
                $('.modal input[name="type"]')
                    .attr("checked", false)
                    .attr("disabled", false);
                $('.modal input[value="' + $(this).data("type") + '"]').attr(
                    "checked",
                    true,
                );
                $('.modal input[name="type"]').attr("disabled", true);
            } else {
                $('.modal input[name="type"]')
                    .attr("checked", false)
                    .attr("disabled", false);
            }
            if ($(this).data("job") !== "") {
                $('.modal input[name="job"]')
                    .attr("disabled", true)
                    .val($(this).data("job"));
            } else {
                $('.modal input[name="job"]')
                    .attr("disabled", false)
                    .val($(this).data("job"));
            }
            $(".modal_overlay").removeClass("hidden");
            $(".modal").removeClass("hidden");
            $(".modal_close").removeClass("hidden");
            $("body").addClass("overflow-hidden");
        }
    });

    $("body").on("click", ".job_filter", function () {
        if ($(this).data("action") === "filter_region") {
            filter_region = $(this).children().text();
            var body = $(this)
                .parent()
                .parent()
                .parent()
                .parent()
                .parent()
                .children(".collapse_body");
            var id = body.attr("id");
            $(this)
                .parent()
                .children(".collapse_dropdown_filter")
                .each(function () {
                    $(this).removeClass("collapse_dropdown_filter-active");
                });
            $(this).addClass("collapse_dropdown_filter-active");
            $(this)
                .parent()
                .parent()
                .children(".collapse_dropdown_header")
                .children(".collapse_dropdown_label")
                .text(filter_region);
            $.get(
                urls.ajax,
                {
                    action: "job_filter",
                    filter_region: filter_region,
                    filter_type: filter_type,
                    filter_genre: filter_genre,
                },
                function (data) {
                    $(body).html(data);
                    body.next().children(".paginate").jPages({
                        containerID: id,
                        perPage: 6,
                        first: false,
                        previous: false,
                        next: false,
                        last: false,
                    });
                },
            );
        } else if ($(this).data("action") === "filter_type") {
            filter_type = $(this).children().text();
            var body = $(this)
                .parent()
                .parent()
                .parent()
                .parent()
                .parent()
                .children(".collapse_body");
            var id = body.attr("id");
            $(this)
                .parent()
                .children(".collapse_dropdown_filter")
                .each(function () {
                    $(this).removeClass("collapse_dropdown_filter-active");
                });
            $(this).addClass("collapse_dropdown_filter-active");
            $(this)
                .parent()
                .parent()
                .children(".collapse_dropdown_header")
                .children(".collapse_dropdown_label")
                .text(filter_type);
            $.get(
                urls.ajax,
                {
                    action: "job_filter",
                    filter_region: filter_region,
                    filter_type: filter_type,
                    filter_genre: filter_genre,
                },
                function (data) {
                    $(body).html(data);
                    body.next().children(".paginate").jPages({
                        containerID: id,
                        perPage: 6,
                        first: false,
                        previous: false,
                        next: false,
                        last: false,
                    });
                },
            );
        } else if ($(this).data("action") === "filter_genre") {
            filter_genre = $(this).children().text();
            var body = $(this)
                .parent()
                .parent()
                .parent()
                .parent()
                .parent()
                .children(".collapse_body");
            var id = body.attr("id");
            $(this)
                .parent()
                .children(".collapse_dropdown_filter")
                .each(function () {
                    $(this).removeClass("collapse_dropdown_filter-active");
                });
            $(this).addClass("collapse_dropdown_filter-active");
            $(this)
                .parent()
                .parent()
                .children(".collapse_dropdown_header")
                .children(".collapse_dropdown_label")
                .text(filter_genre);
            $.get(
                urls.ajax,
                {
                    action: "job_filter",
                    filter_region: filter_region,
                    filter_type: filter_type,
                    filter_genre: filter_genre,
                },
                function (data) {
                    $(body).html(data);
                    body.next().children(".paginate").jPages({
                        containerID: id,
                        perPage: 6,
                        first: false,
                        previous: false,
                        next: false,
                        last: false,
                    });
                },
            );
        }
    });

    $(".programsList")
        .parent()
        .children("input")
        .keyup(function () {
            if ($(this).val().length >= 1) {
                let programsList = $(this).parent().children(".programsList");
                programsList.addClass("contactform_list-open");
                $.post(
                    urls.ajax,
                    {
                        action: "sponsorship_programs",
                        name: $(this).val(),
                    },
                    function (data) {
                        JSON.parse(data).forEach(function (program) {
                            programsList.append(
                                '<li class="contactform_list_item">' +
                                    program.name +
                                    "</li>"
                            );
                        });
                    },
                );
            }
        });

    $(document).on("click", ".programsList li", function () {
        $(this).parent().parent().children("input").val($(this).text());
        $(this).parent().removeClass("contactform_list-open");
    });

    document.addEventListener("click", () => {
        document.querySelectorAll(".contactform_list-open").forEach((list) => {
            list.classList.remove("contactform_list-open");
        });
    });
});
