<script setup lang="ts">
import { computed } from 'vue';

defineProps<{
    program: Record<string, any>;
}>();

const isDesktop = computed(() => window.innerWidth > 1024);

const getSmallestTvaValue = (lots: Record<string, any>) => {
    const tvaValues: number[] = [];
    Object.values(lots).filter(item => {
        item.forEach((element: any) => {
            if(element.pourcentage_taux_tva) {
                tvaValues.push(parseFloat(element.pourcentage_taux_tva))
            }
        });
    });
    if(tvaValues.length > 0) {
        return `(TVA ${tvaValues[0]} %)`;
    }
};

const formatPrice = (price: number): string => {
    const formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    return formatter.format(price);
};
</script>

<template>
    <!-- card -->
    <div class="col-span-1 pt-6 w-full">
        <div class="h-full flex flex-col justify-between bg-white rounded-lg">
            <div>
                <!-- header -->
                <div class="relative">
                    <div class="absolute top-0 left-5 -translate-y-4 flex flex-col items-start gap-2">
                        <div v-if="program?.status" class="px-2 py-1 bg-white text-primary rounded-sm uppercase">
                            {{ program?.status?.label }}
                        </div>
                        <div v-if="program?.has_offer" class="px-2 py-1 bg-primary text-white rounded-sm uppercase font-semibold">
                            Offre spéciale
                        </div>
                    </div>

                    <a v-if="isDesktop" :href="program?.permalink" alt="{{ program?.name }}">
                        <img
                            class="w-full h-64 object-cover object-center rounded-lg"
                            :src="program?.banner"
                            :alt="program?.name"
                        >
                    </a>
                    <img
                        v-else
                        class="w-full h-64 object-cover object-center rounded-lg"
                        :src="program?.banner"
                        :alt="program?.name"
                    >
                </div>
                <!-- body -->
                <div class="p-4 space-y-2">
                    <div>
                        <h2 class="font-bold uppercase">
                            {{ program?.name }}
                        </h2>
                        <h3 class="uppercase">
                            {{ program?.ville }} ({{ program?.code_postal.slice(0, 2) }})
                        </h3>
                    </div>
                    <div v-if="program?.badges && program?.badges?.length" class="flex flex-wrap gap-2">
                        <div
                            v-for="(badge, index) in program?.badges"
                            :key="index"
                            class="px-1.5 py-0.5 bg-secondary text-white uppercase rounded"
                        >
                            {{ badge.label }}
                        </div>
                    </div>
                    <p>
                        <span v-if="program?.min_room !== program?.max_room">

                            Du <strong>{{ program?.min_room > 1 ? `${program?.min_room} pièces` : 'studio' }}</strong> au <strong>{{ program?.max_room }} pièces</strong>
                        </span>
                        <span v-else>
                            <strong>{{ program?.min_room > 1 ? `${program?.min_room} pièces` : 'studio' }}</strong>
                        </span>
                        <template v-if="program?.min_price != 0">
                            <br>
                            À partir de <strong>{{ formatPrice(program?.min_price) }}</strong> {{ getSmallestTvaValue(program?.lots) }}
                        </template>
                    </p>
                </div>
            </div>
            <!-- footer -->
            <div class="p-4">
                <a :href="program?.permalink"
                    target="_blank"
                    class="px-3 py-2 inline-flex items-center gap-2 font-bold text-white bg-primary uppercase rounded"
                >
                    Découvrir le programme
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                    </svg>
                </a>
            </div>
        </div>
    </div>
</template>
