// import Splide from '@splidejs/splide';
// import '@splidejs/splide/css';
import { HomepageHeroSlider } from '../homepage/hero-slider';
import { disablePageScroll } from '@/utils/modals';
import { OffersSlider } from '../offers-slider';
import axios from "axios";
import { gtmEvent } from '../../../scripts/includes/gtm';
import {
    displayFormSubmitMessage,
    handleInvestInput,
    handleFormValidation,
    getProgramContactSession,
    setProgramContactSession
} from '@/utils/forms';

let gtmIdOperation: string | null;
let gtmProgramName: string | null;

const getFormAttributes = (element: HTMLElement) => {
    const form = element.querySelector('form');

    if(!form) return;

    gtmIdOperation = form?.getAttribute('data-program-id-operation');
    gtmProgramName = form?.getAttribute('data-program-name');
}

const setGtmLeadEvent = (success: boolean) => {
    gtmEvent('Lead', {
        form: {
            id: "Contact form",
            title: "Contact form"
        },
        program: {
            id: gtmIdOperation ?? '',
            title: gtmProgramName ?? '',
        },
        success: success
    });
}

const handleDisplayBooklet = () => {
    const programBookletImage = document.querySelector('.programBookletImage');
    const programBookletPDF = document.querySelector('.programBookletPDF');

    if(getProgramContactSession(gtmIdOperation as string, 'brochure')) {
        programBookletImage?.classList.add('hidden');
        programBookletPDF?.classList.remove('hidden');
    }
}

document.addEventListener("DOMContentLoaded", function () {
    const programFormDialog: HTMLElement | null = document.querySelector(
        ".programContactFormDialog",
    );

    const defaultForm: HTMLElement | null = document.querySelector('.defaultForm');
    const bookletForm: HTMLElement | null = document.querySelector('.bookletForm');

    const openFormButton: HTMLElement | null = document.querySelector(
        "#closeProgramContactFormDialog",
    );

    let sliderType: String | null
    // Submit form
    const submitForm = async (form: HTMLFormElement, data: FormData) => {
        let submit = false;
        let autoclose = true;
        try {

            if(sliderType === 'booklet') handleInvestInput('bookletForm', data);

            const { errors } = handleFormValidation(programFormDialog, data) ?? { errors: [] };

            if(errors.length) {
                errors.forEach((error: any) => {
                    // console.log('errors :', errors, 'input :', programFormDialog?.querySelector(`[name="${error.field}"]`));
                    programFormDialog?.querySelector(`[name="${error.field}"]`)?.classList.add('border-red');
                    programFormDialog?.querySelector(`[for="${error.field}"]`)?.classList.add('text-red');
                })

                return;
            }

            // @ts-ignore
            const response = await axios.post(urls.ajax, data)
            
            if(response.status !== 200) {
                throw new Error('Error submitting program contact form');
            } else {
                submit = true;
                displayFormSubmitMessage('success', 'Votre message a bien été envoyé');
                setGtmLeadEvent(true);

                if(sliderType === 'booklet') {
                    if(getProgramContactSession(gtmIdOperation as string, 'brochure')) handleDisplayBooklet();
                    else {
                        setProgramContactSession(gtmIdOperation as string, 'brochure');
                        handleDisplayBooklet();

                        autoclose = false;
                    }
                }

                form.reset();
            }
        } catch (error: any) {
            if(error.message) {
                displayFormSubmitMessage('error', error.message);
            } else {
                displayFormSubmitMessage('error', 'Une erreur est survenue lors de l\'envoi de votre message');
            }
            setGtmLeadEvent(false);
            console.error(error);
        } finally {
            if(submit && autoclose) {
                setTimeout(() => {
                    programFormDialog?.classList.add("hidden");
                    disablePageScroll(false);
                }, 5000);
            }
        }
    }

    // FORM GALLERY
    document.querySelectorAll(".openProgramForm")?.forEach((element) => {
        element?.addEventListener("click", () => {
            programFormDialog?.classList.toggle("hidden");
            
            disablePageScroll(true)

            sliderType = element.getAttribute("data-slider-type");

            if(sliderType === 'booklet') {
                bookletForm?.classList.toggle("hidden");
                if(bookletForm) getFormAttributes(bookletForm);
                if(getProgramContactSession(gtmIdOperation as string, 'brochure')) handleDisplayBooklet();
            } else {
                defaultForm?.classList.toggle("hidden");
                if(defaultForm) getFormAttributes(defaultForm);
            }

            const targetSection = programFormDialog?.querySelector(`[data-slider-type="${sliderType}"]`);

            if (targetSection) {
                let slider
                if(sliderType === 'image') {
                    slider = new HomepageHeroSlider('.programContactOffersSlider')
                    if(slider) targetSection.classList.remove('hidden');
                } else if (sliderType === 'booklet') {
                    targetSection.classList.remove('hidden');
                } else {
                    slider = new OffersSlider()
                    targetSection.classList.remove('hidden');
                }
            } else {
                programFormDialog?.querySelector('.fallbackImage')?.classList.remove('hidden');
            }
        });
    });

    openFormButton?.addEventListener("click", () => {
        disablePageScroll(false)
        
        programFormDialog?.scrollTo(0, 0);

        programFormDialog?.classList.add("hidden");
        bookletForm?.classList.add("hidden");
        defaultForm?.classList.add("hidden");

        const targetSection = programFormDialog?.querySelector(`[data-slider-type="${sliderType}"]`);
        
        const fallbackImage = programFormDialog?.querySelector('.fallbackImage');
        fallbackImage?.classList.add('hidden');
        
        if (sliderType) {
            targetSection?.classList.add('hidden');
        } 
    });

    programFormDialog?.addEventListener("submit", (event) => {
        event.preventDefault();
        const form = event.target as HTMLFormElement;
        const formData = new FormData(form);
        submitForm(form, formData);
    });
});
