import Swiper from 'swiper/bundle'
import 'swiper/css/bundle';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export class HomepageHeroSlider {
    private static readonly PROGRAM_HEADER_SLIDER_ID = 'programHeaderSlider';
    private slider: HTMLElement;
    private sliderComponent!: Swiper;
    private sliderSelector!: String;
    private sliderContainer: HTMLElement;
    private sliderItems!: Array<HTMLElement>;
    private sliderImages: NodeListOf<HTMLElement> | undefined;
    private currentIndex!: number;
    private sliderTextContent: NodeListOf<HTMLElement> | undefined;
    private sliderFullscreen: boolean = false;
    private closeFullscreenBtn: HTMLElement | null = document.querySelector('.closeProgramHeaderSlider');

    constructor(private sliderId: String) {
        this.sliderContainer = document.querySelector(`[data-slider-id="${this.sliderId}"]`)!;
        this.slider = document.querySelector(`#${this.sliderId}`)!;

        this.sliderSelector = sliderId

        // Slider images
        this.sliderItems = Array.from(this.sliderContainer.querySelectorAll('.swiper-slide'));
        this.sliderImages = this.sliderContainer.querySelectorAll('.sliderImage');
        
        // Homepage slider text content
        this.sliderTextContent = document.querySelectorAll('[data-slide-content-id]');

        this.currentIndex = 0;

        this.setupSplideSlider();
        
        // CLose fullscreenmode
        this.closeFullscreenBtn?.addEventListener('click', () => this.handleCloseFullscreen())
    }

    private enterFullscreen = (element: HTMLElement) => {
        element.classList.add('cursor-zoom-in');
        element.setAttribute('title', "Double cliquez pour zoomer dans l'image");
        
        const requestFullscreen = 
            element.requestFullscreen ||
            // @ts-expect-error Vendor prefixes
            element.webkitRequestFullscreen ||
            // @ts-expect-error
            element.mozRequestFullScreen ||
            // @ts-expect-error
            element.msRequestFullscreen;

        if (requestFullscreen) {
            requestFullscreen.call(element);
        }
    }

    private handleImageCover = (fullscreen = true) => {
        this.sliderImages?.forEach((image) => {
            if(fullscreen) image.style.objectFit = 'contain';
            else image.style.objectFit = 'cover';
        })

    }
    
    private exitFullscreen = () => {
        const exitFullscreen = 
            document.exitFullscreen ||
            // @ts-expect-error Vendor prefixes
            document.webkitExitFullscreen ||
            // @ts-expect-error
            document.mozCancelFullScreen ||
            // @ts-expect-error
            document.msExitFullscreen;

        if (exitFullscreen) {
            exitFullscreen.call(document);
        }
    }

    private async openFullscreen(isOPen: Boolean): Promise<void> {;
        if(this.sliderImages) {
            this.handleImageCover(true);
        }

        if(!isOPen) {
            this.closeFullscreenBtn?.classList.toggle('hidden');

            this.sliderFullscreen = true;
            this.enterFullscreen(this.slider);
        }
    }

    private handleCloseFullscreen = () => {
        this.sliderComponent.zoom.out();
        this.closeFullscreenBtn?.classList.add('hidden');
        this.slider?.classList.remove('cursor-zoom-in', 'cursor-zoom-out');
        this.slider?.classList.add('cursor-pointer');
        this.slider.removeAttribute('title');
        this.sliderFullscreen = false;

        this.handleImageCover(false);

        this.exitFullscreen();
    
    }
    
    private setupSplideSlider(): void {
        this.sliderComponent = new Swiper(`.${this.sliderSelector}`, {
            zoom: true,
            loop: true,
            // effect: 'fade',
            // fadeEffect: {
            //     crossFade: true
            // },
            a11y: {
                prevSlideMessage: 'Slide précédente',
                nextSlideMessage: 'Slide suivante',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
        })

        this.sliderComponent.on('slideChange', (slider) => {
            if (slider.realIndex > slider.previousIndex) {
                this.changeSlide(1, slider.realIndex);
            } else {
                this.changeSlide(-1, slider.realIndex);
            }
            
        })

        if(this.sliderId === HomepageHeroSlider.PROGRAM_HEADER_SLIDER_ID) {
            this.sliderComponent.on('click', () => {
                this.openFullscreen(this.sliderFullscreen)
            })

            document.addEventListener('fullscreenchange', () => {
                // Check if we've exited fullscreen and the slider was in fullscreen mode
                if (!document.fullscreenElement && this.sliderFullscreen) {
                    this.handleCloseFullscreen();
                }
            });
        }
    }


    private changeSlide(direction: number, currentIndex: number): void {
        this.currentIndex += direction;

        // Circular navigation
        this.currentIndex = (this.currentIndex + this.sliderItems.length) % this.sliderItems.length;

        this.sliderTextContent?.forEach((el, idx) => {
            const isCurrentSlide = idx === currentIndex;

            if(!el.querySelector('span[style]')) {
                el.querySelector('del')?.classList.add('text-primary');
            }
            
            el.classList.toggle('translate-y-full', !isCurrentSlide);
            el.classList.toggle('opacity-0', !isCurrentSlide);
        });
    }


    public static initSliders(): void {
        document.addEventListener('DOMContentLoaded', () => {
            document.querySelectorAll('[data-slider-id]').forEach(el => {
                const sliderId = el.getAttribute('data-slider-id');
                if (sliderId) {
                    new HomepageHeroSlider(sliderId)
                }
            });
        });
    }
}

HomepageHeroSlider.initSliders();
