document.addEventListener('DOMContentLoaded', () => {
    const optinInputs: NodeListOf<HTMLElement> = document.querySelectorAll('.optin-input');

    optinInputs?.forEach(optinInput => {
        // We only need one click handler on the wrapper div
        optinInput.addEventListener('click', (e: Event) => {
            const input: HTMLInputElement | null = optinInput.querySelector('input[name="mailing_ok"]');
            
            // If the click was directly on the input, don't do anything
            // Let the browser handle the native checkbox behavior
            if (e.target instanceof HTMLInputElement && e.target.type === 'checkbox') {
                return;
            }

            // If we clicked the wrapper or label, toggle the checkbox
            if (input) {
                e.preventDefault();
                input.checked = !input.checked;
            }
        });
    });
});