// Default theme
import Splide from '@splidejs/splide';
import '@splidejs/splide/css';

class SimilarProgramsSlider {
    constructor() {
        const similarProgramsSlider: HTMLElement | null = document.querySelector('.similarProgramsSlider');
        if(similarProgramsSlider) {
            this.setupSplides(similarProgramsSlider)
        };

    }

    private setupSplides(similarProgramsSlider: HTMLElement): void {
            const splide = new Splide(similarProgramsSlider, {
                breakpoints: {
                    0: {
                    height: this.setSliderHeight(),
                    width: '100vw',
                    },
                },
                classes: {
                    pagination: 'splide__pagination slider-pagination-container pagination-residences-categories max-md:!relative md:!hidden max-md:!pt-6 !flex !justify-center !items-center !block ',
                    page: 'splide__pagination__page slider-pagination-items',
                },
                perPage: this.setSliderPerPage(),
                dataAttribute: 'data-program-status'
            })

            splide.mount();
    }

    private setSliderPerPage = () => {
        return window.innerWidth > 1920 ? 4
            : window.innerWidth > 1024 ? 4
                : window.innerWidth > 500 ? 2 : 1;
    }

    private setSliderHeight = () => {
        return window.innerWidth < 1024 ? '650px' : '';
    }

    public static init(): void {
        document.addEventListener("DOMContentLoaded", function () {
            new SimilarProgramsSlider();
        });
    }
}

SimilarProgramsSlider.init();
